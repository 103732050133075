<template>

    <b-card>

        <b-row>
            <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

                    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
                        <feather-icon
                                    icon="ListIcon"
                                    size="21"
                                    class="color_icon"
                                    style="margin-right: 10px;"
                                    />Listado de mis adelantos de sueldo
                            </h4>

         
                    </b-col>

                    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">
                    <b-skeleton v-if="loading" width="100%"  height="20px"></b-skeleton>
                    <p v-else style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                                <feather-icon
                                                                icon="RefreshCcwIcon"
                                                                size="16"
                                                                style="margin-right:5px"
                                                                />  </p>
    
                    
                    </b-col>
        </b-row>

        <b-row v-if="loading">
          <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
              
              
            </b-col>
            
            <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
            
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            </b-col>
            
            <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
            
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
                </b-col>
            
                <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
            
                <b-skeleton width="100%"  height="30px"></b-skeleton>
            
                </b-col>
            
                <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
                </b-col>
            
            <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">
            
            
            
            
                              <b-skeleton-table
                          :rows="2"
                          :columns="columnas"
                          :table-props="{ bordered: true, striped: true }"
                        ></b-skeleton-table>
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
            
            <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
            
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
            
            <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            
            </b-col>
            
            <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
            <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>
            
            
            </b-col>
            
            <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            
            </b-col>
            
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
        </b-row>


        <b-row v-else-if="code200">
   
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto;padding: 0px; margin-top:15px">
    
      <table class="table tablesorter"  id="datatableEmpleados" >
                                  <thead class="thead-light">
                                    <tr>
                                      <th>Fecha/hora</th>
                                      <th>Monto solicitado</th>
                                      <th>Monto recibido</th>
                                      <th>Estado</th>
                                      <th>Próxima fecha de pago</th>
                                      <th>Fecha real de pago</th>
                                     
                                    </tr>
                                  </thead>
                                  <tbody>
    
    
                                    <tr v-for="adelanto in adelantos" :key="adelanto.id">
    
                                     <td> {{ adelanto.dateFormated }}</td>
                                     <td>${{ adelanto.mount_real }} USD</td>
                                     <td>${{ adelanto.mount }} USD</td>
                                   
                                     <td>

                                      <div v-if="adelanto.activo" >

                                        <b-badge  variant="warning">
                                        <span style="font-size: 12px;">Pendiente de pago</span> 
                                          </b-badge>

                                        <b-button
                                        style="margin-top:10px"
                                         size="sm"
                                            variant="primary"
                                            @click="pay(adelanto.id, adelanto.mount_real)"
                                            block
                                          >
                            
                                            Pagar
                            
                                          </b-button>
                                      </div>
                                     

                                          <b-badge v-else  variant="primary">
                                        <span style="font-size: 12px;">Pagado</span> 
                                          </b-badge>
                                     </td>
                                     <td> {{ adelanto.proxima_fecha_pago }}</td>
                                     <td> {{ adelanto.fecha_real_pago }}</td>
    
                                    </tr>
                                  </tbody>
                                </table>
    
    
     </b-col>

     <b-modal
            ref="modalPayAdelanto"
            centered
            hide-footer
            size="sm"
           title="Pago de adelanto de sueldo"
          >
            <div class="d-block text-center">
      
              <b-form
                autocomplete="off"
                @submit.prevent="onSubmit"
              >
      
                <b-row>

                  <b-col
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                              >

                       
                                   <b-alert
                              variant="secondary"
                              show
                              style="margin-top:10px;margin-bottom: 10px;"
                            >
                              <div class="alert-body">
                           
                                <p  class="saldoDisponible"><span style="font-size:25px">$</span>{{mount}}<small class="currency2">USD</small></p>
                                <p style="font-size: 12px;    margin: 0;">Cantidad a pagar del adelanto de sueldo</p>
    
                              
                              </div>
                            </b-alert>
    
                            
    
                              </b-col>
    
    
                 
                  <b-col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    style="margin-top:10px"
                  >
      
                    <p
                      class="textLabel"
                      style="text-align: center;margin-bottom: 10px;"
                    >
                      Ingrese su código PIN (*): <feather-icon
                                        icon="HelpCircleIcon"
                                        size="16"
                                          v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                        title="Código PIN de seguridad"
                                        style="margin-right: 5px;cursor:pointer;"
                                        /> </p>
      
                    <PincodeInput
                      v-model="pin"
                      placeholder="-"
                      :length="6"
                      :autofocus="false"
                      :secure="true"
                      :disabled="isActive"
                      required
                    />
      
                  </b-col>
      
                  <b-col
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                 
                  >

      
                    <b-button
                      id="btn_pay"
                      ref="btn_pay"
                      type="submit"
                      variant="primary"
                      :disabled="isActive"
                      block
                      
                    >
      
                     <span style="font-size:14px; font-weight: bold;">Pagar <span style="font-size:12px">$</span>{{ mount }} <span style="font-size:12px">USD</span></span> 
      
                    </b-button>
      
                  </b-col>
      
                </b-row>
              </b-form>
      
            </div>
      
          </b-modal>
    
    
     
    
     </b-row>
    
     <b-row v-else-if="empty">
    
    
    
     <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12" >
      <feather-icon
              icon="UsersIcon"
              size="45"
              class="color_icon"
            />                             
    
                             <p style="margin-top:15px">
                            No se encontraron adelantos de sueldo
                          </p>
    
                  </b-col>
    

    </b-row>

   

    </b-card>
      
      </template>
      
      <script>
      import {
        BButton, BRow, BCol, BCard, BSkeleton, BSkeletonTable, BBadge, BAlert,VBPopover, BForm
      } from 'bootstrap-vue'
      
      import "jquery/dist/jquery.min.js";
    
    import $ from "jquery";
    import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
    import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
    import PincodeInput from 'vue-pincode-input'
      export default {
        components: {
          BSkeleton,
          BButton,
          BRow,
          BCol,
          BBadge,
          BCard,
          BSkeletonTable,
          BAlert,
          PincodeInput,
          BForm
        },
        directives: {
              'b-popover': VBPopover,
            },
        props: ['userId', 'tokenAuth', 'idLocalEmployee'],
        data() {
      
          const mq = window.matchMedia( "(min-width: 990px)" );
            let columnas=6;
    
            if (!mq.matches) {
    
              columnas =3;
              }
          return {
            code200: false,
          loading: true,
           empty:false,
           columnas:columnas,
           adelantos:[],
           mount:"",
           idAdelanto:"",
           pin:"",
           isActive:false,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
          this.loadTable();
    
    
        },
        methods: {

          pay(id, mount){
            this.pin="";
           this.idAdelanto= id;
           this.mount= Number(mount).toFixed(2);
           this.$refs.modalPayAdelanto.show()
          },

          onSubmit(event) {
          event.preventDefault();
    
    
    
            this.isActive=true;
          
            const local_json = {
               userId: this.userId, idAdelanto: this.idAdelanto, pin: this.pin
              }
    
            
    
    
    
            const local_string = JSON.stringify(local_json)
      
            const dataEncripted = this.$encryptBackoffice.encrypt(local_string)
     
            document.getElementById('btn_pay').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" /> Realizando pago'
    
         
          this.$https.post('/locals/payAdelanto/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
            document.getElementById('btn_pay').innerHTML = 'Pagar $'+this.mount+"USD";
            this.isActive=false;
          
        
          if (response.data.code == 200) {
    
            this.$eventBus.$emit('reiniciarListadoAdelantos')
            this.$eventBus.$emit('reiniciarAdelantos');  
            this.$eventBus.$emit('reiniciarRetiro2');  

         
         
            this.$refs.modalPayAdelanto.hide();
    
            this.$toast.success(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            });
    
          
    
          } else {
    
            this.$toast.error(response.data.message, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
    
          if (response.data.code == 401) {
    
          
    
            localStorage.removeItem('userData')
    
    
            this.$router.push({ name: 'auth-login' })
          }
          }
          }).catch(error => {
            this.$toast.error("Ocurrio un error " + error, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
    
              
    
    
        },
  
          refresh() {
          this.$eventBus.$emit('reiniciarListadoAdelantos')
        }, loadTable(){
          
            const data_json = { userId: this.userId, id: this.idLocalEmployee }
                
    
          this.$https.post('/locals/getAdelantosSueldo/', { tokenAuth: this.tokenAuth, data: data_json }).then(response => {
             this.loading=false;

             console.log(JSON.stringify(response.data))

         
                          if(response.data["code"] == 200){
                          
                         
                    
                           
    
                            this.adelantos= response.data["adelantos"];
     
                     
                                      setTimeout(() => {
                                        $("#datatableEmpleados").DataTable({
                                          responsive: true,
                                          "ordering": false,
                                          lengthMenu: [
                                            [5,10, 25, 50, -1],
                                            [5,10, 25, 50, "All"],
                                          ],
                                          pageLength: 5, 
                                             "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                                        });
                                      });
    
                                         this.code200=true;
    
                         
      
                              
                            }else{
    
               
                                
    
    
                                      if (response.data.code == 401) {
    
                                        this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })
    
    
                                        
                                        localStorage.removeItem('userData')
    
    
    
                                        
                                        
    
               
    
                                        
                                        
    
                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })
                                      } else {
    
                                

                                            if (response.data.code == 405) {
                                                this.empty=true;
                                            }else{
                                                this.loadTable(); 
                                            }

                                            
    
                                        
                                      
    
    
                                      }
    
                            }
                     }).catch((error) => {
    
                              this.loadTable();
    
                         
                     });
        }
         
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      